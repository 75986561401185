import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
/**
 * Loaders
 */
const loadingManager = new THREE.LoadingManager(
  // Loaded callback
  () => {
    gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 1, value: 0 });
    // Hide the loading screen
    // if(document.getElementById("spinner") && document.getElementById("fullscreen-overlay")){
    // document.getElementById("spinner").style.display = "none";
    // document.getElementById("fullscreen-overlay").style.display = "none";
    // }
    // window.zipcpqEmitEvent && window.zipcpqEmitEvent("file-loaded", null);
  },
  (url, itemsLoaded, itemsTotal) => {
    // Update the progress bar
    const progressRatio = itemsLoaded / itemsTotal;
  }
);
loadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
  // if(document.getElementById("spinner") && document.getElementById("fullscreen-overlay")){
  // document.getElementById("spinner").style.display = "block";
  // document.getElementById("fullscreen-overlay").style.display = "block";
  // }
};

// Hide spinner once everything has finished loading
loadingManager.onLoad = function () {
//   if(document.getElementById("spinner") && document.getElementById("fullscreen-overlay")){

//   document.getElementById("spinner").style.display = "none";
//   document.getElementById("fullscreen-overlay").style.display = "none";
// }

//   window.zipcpqEmitEvent && window.zipcpqEmitEvent("file-loaded", null);
};
const debugObject = {}
const scene = new THREE.Scene()
const gltfLoader = new GLTFLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader()
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')
gltfLoader.setDRACOLoader(dracoLoader)
let renderer,controls
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 100)
camera.position.set(3, 1, 3)
scene.add(camera)

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
  'https://viewer-dev.zipcpq.com/viewer/textures/environmentMaps/0/px.jpg',
  'https://viewer-dev.zipcpq.com/viewer/textures/environmentMaps/0/nx.jpg',
  'https://viewer-dev.zipcpq.com/viewer/textures/environmentMaps/0/py.jpg',
  'https://viewer-dev.zipcpq.com/viewer/textures/environmentMaps/0/ny.jpg',
  'https://viewer-dev.zipcpq.com/viewer/textures/environmentMaps/0/pz.jpg',
  'https://viewer-dev.zipcpq.com/viewer/textures/environmentMaps/0/nz.jpg'
])

const environmentColor= new THREE.Color("#f6f6f6")

const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.castShadow = true
            // child.receiveShadow = true
        }
    })
}


scene.background = environmentColor
scene.environment = environmentMap
debugObject.envMapIntensity = 1.5
// gui.add(debugObject, 'envMapIntensity').min(0).max(10).step(0.001).onChange(updateAllMaterials)
// '/models/Direct/'+
/**
 * Models
 */

/* View in fullscreen */
window.fullscreen = function openFullscreen() {
	var elem = document.getElementById("modelCanvas"); 
	if(elem){
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) { /* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE11 */
			elem.msRequestFullscreen();
		}
	}
}

const directionalLight = new THREE.DirectionalLight('#ffffff', 1.2)
// directionalLight.castShadow = true
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(206, 206)
directionalLight.shadow.normalBias = 0.001
scene.add(directionalLight)
const directionalLight1 = new THREE.DirectionalLight('#ffffff', 0)
directionalLight1.castShadow = true
directionalLight1.shadow.camera.far = 15
directionalLight1.shadow.mapSize.set(206, 206)
directionalLight1.shadow.normalBias = 0.001
scene.add(directionalLight1)
const directionalLight2 = new THREE.DirectionalLight('#ffffff', 0.2)
// directionalLight2.castShadow = true
directionalLight2.shadow.camera.far = 15
directionalLight2.shadow.mapSize.set(206, 206)
directionalLight2.shadow.normalBias = 0.001
scene.add(directionalLight2)

  // Floor
const material = new THREE.ShadowMaterial({
    opacity: 0.1,
});

const plane = new THREE.Mesh(new THREE.PlaneGeometry(1500, 1500), material);
plane.rotation.x = -Math.PI * 0.5;
plane.name="Floor"
plane.receiveShadow = true;
plane.castShadow = true;

window.clr = function clearScene() {
	var dd = scene.getObjectByName("Root")
	scene.remove(dd)
}
window.LoadGltf = function loading(type,gltf){
	// Controls
	let canvas = document.querySelector('canvas.webgl')
	// Update sizes
	let width = document.getElementById('modelCanvas').clientWidth
	let height = document.getElementById('modelCanvas').clientHeight
	// Update camera
	camera.aspect = width / height
	camera.updateProjectionMatrix()

	controls = new OrbitControls(camera, canvas)
	controls.enableDamping = true
	/**
	 * Renderer
	 */
	renderer = new THREE.WebGLRenderer({
		canvas: canvas,
		antialias: true
	})
	renderer.physicallyCorrectLights = true
	renderer.outputColorSpace = THREE.SRGBColorSpace
	// renderer.toneMapping = THREE.ReinhardToneMapping
	// renderer.toneMappingExposure =3
	renderer.shadowMap.enabled = true
	renderer.shadowMap.type = THREE.PCFSoftShadowMap
	renderer.setSize(width, height)
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

	if(type == "gltf"){
		gltfLoader.load(
			gltf,
			(gltf) =>
			{
				gltf.scene.scale.set(5,5,5)
				gltf.scene.position.set(0, 0, 0)
				gltf.scene.rotation.x = -Math.PI * 0.5
				scene.add(gltf.scene)
				updateAllMaterials()
			}
		)
    }
    else if (type == "json" ){
        gltfLoader.parse(
            JSON.stringify(gltf),"",
            (file)=>
            {
                file.scene.scale.set(5,5,5)
                file.scene.position.set(0, 0, 0)
                file.scene.rotation.x = -Math.PI * 0.5
                file.scene.castShadow=true
                scene.add(file.scene)
				scene.add(plane)
                const bbox = new THREE.Box3().setFromObject(file.scene);

				// Calculate the center position and size of the bounding box
				const center = new THREE.Vector3();
				const size = new THREE.Vector3();
				bbox.getCenter(center);
				bbox.getSize(size);
				const planeHeight = bbox.min.y // Adjust the value as needed
				// Calculate the maximum dimension of the bounding box
				const maxDimension = Math.max(size.x, size.y, size.z);

				// Calculate the distance from the camera to fit the bounding box within the screen
				const distance = maxDimension / (2 * Math.tan(THREE.MathUtils.degToRad(camera.fov) / 2));
				// Set the camera position and target based on the bounding box
				const cameraPosition = new THREE.Vector3();
				cameraPosition.x += (distance/1.3);
				cameraPosition.y += (distance/1.3);
				cameraPosition.z += (distance/1.3);
				camera.position.copy(cameraPosition);
				camera.lookAt(center);
				controls.target.copy(center);
				plane.position.copy(center);
				plane.position.y = planeHeight;
				directionalLight.position.set(0.25, bbox.max.y, 0.083)
				directionalLight1.position.set(0.25, bbox.max.y+5, 0.083)
				directionalLight2.position.set(bbox.max.x, bbox.max.y, bbox.max.z)
				updateAllMaterials()
            }
        )
	updateAllMaterials()
    }
	tick()
}
updateAllMaterials()

// gltfLoader.load(
//     '/models/hamburger.glb',
//     (gltf) =>
//     {
//         gltf.scene.scale.set(0.3, 0.3, 0.3)
//         gltf.scene.position.set(0, - 1, 0)
//         scene.add(gltf.scene)

//         updateAllMaterials()
//     }
// )

/**
 * Lights
 */


// gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
// gui.add(directionalLight.position, 'x').min(- 5).max(5).step(0.001).name('lightX')
// gui.add(directionalLight.position, 'y').min(- 5).max(5).step(0.001).name('lightY')
// gui.add(directionalLight.position, 'z').min(- 5).max(5).step(0.001).name('lightZ')

// window.addEventListener("DOMContentLoaded", function() {
//     const spinner = document.getElementById("spinner");
//     const overlay = document.getElementById("fullscreen-overlay");

//     const canvasRect = modelCanvas.getBoundingClientRect();
//     const width = canvasRect.width;
//     const height = canvasRect.height;
//     const canvasWidth = canvasRect.width;
//     const canvasHeight = canvasRect.height;
//     spinner.style.position = "absolute";
//     spinner.style.top = canvasHeight / 2 - spinner.offsetHeight / 2 + "px";
//     spinner.style.left = canvasWidth / 2 - spinner.offsetWidth / 2 + "px";
//     // spinner.style.width = width + "px";
//     // spinner.style.height = height + "px";
//     overlay.style.width = width + "px";
//     overlay.style.height = height + "px";
//     // Your code here
//   });


window.addEventListener('resize', () =>
{
	if(document.getElementById('modelCanvas') && renderer){
		// Update sizes
		let width = document.getElementById('modelCanvas').clientWidth
		let height = document.getElementById('modelCanvas').clientHeight

		// Update camera
		camera.aspect = width / height
		camera.updateProjectionMatrix()

		// Update renderer
		renderer.setSize(width, height)
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
	}
})


// gui
//     .add(renderer, 'toneMapping', {
//         No: THREE.NoToneMapping,
//         Linear: THREE.LinearToneMapping,
//         Reinhard: THREE.ReinhardToneMapping,
//         Cineon: THREE.CineonToneMapping,
//         ACESFilmic: THREE.ACESFilmicToneMapping
//     })
// gui.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001)

/**
 * Animate
 */
const tick = () =>
{

	// Update controls
	controls.update()

	// Render
	renderer.render(scene, camera)

	// Call tick again on the next frame
	window.requestAnimationFrame(tick)
	
}
